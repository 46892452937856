import React, {useEffect, useState} from "react";
import {Routes, Route, Link} from "react-router-dom";
import Home from "./sample/Home"
import About from "./sample/About";
import Counter from "./sample/Counter";
import Input from "./sample/Input";
import Input2 from "./sample/Input2";
import List from "./sample/List";
import BizContract from "page/biz/contract/List";
import BizContractDetail from "page/biz/contract/Detail";

function App() {
  return (
      <div className="App">
          <Routes>
              <Route path="/page/biz/contract/list" element={<BizContract />} />
              <Route path="/page/biz/contract/detail" element={<BizContractDetail />} />
          </Routes>
      </div>
  );
}

export default App;
